@import url(https://db.onlinewebfonts.com/c/60d0edf5304484e80727187678b8354b?family=Rasputin);

.container {
  font-family: 'Rasputin Regular';
  src: url('/public/fonts/rasputin-regular.woff2') format('woff2'),
       url('/public/fonts/rasputin-regular.woff') format('woff') !important; 
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #0d7b16; /* Dark green */
  margin: 0; /* Removes default margin from the body */
  font-family: Arial, sans-serif; /* Default font, will be overridden for the h1 */
  color: white; /* Sets text color to white for high contrast */
  display: flex; /* Enables flexbox layout */
  align-items: center; /* Centers content vertically */
  justify-content: center; /* Centers content horizontally */
  height: 100vh; /* Full height of the viewport */
  overflow: hidden; /* Prevents scrollbars */
}

.container {
  text-align: center; /* Centers the contents horizontally */
  animation: fadeIn 1.5s ease-in-out; /* Fade-in animation */
}

.logo {
  width: 300px; /* Increased width of the logo */
  margin-bottom: 0px; /* Space between the logo and the text */
  transition: transform 0.3s ease-in-out; /* Smooth transform transition */
}

.logo:hover {
  transform: scale(1.1); /* Slightly enlarge the logo on hover */
}

h1 {
  font-family: 'Rasputin Regular', 'Garamond', serif; /* Uses the Rasputin Regular font, with Garamond as a fallback */
  font-weight: bold; /* Makes the font bold */
  font-size: 2.5em; /* Increases the font size */
  margin: 0; /* Removes default margin around the heading */
  animation: bounce 2s infinite; /* Bounce animation */
}

.contact {
  font-size: 1em; /* Increased font size for contact section */
  margin-top: 20px; /* Space above the contact line */
  animation: fadeInUp 2s ease-in-out; /* Fade-in animation from bottom */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-icon {
  margin-right: 10px; /* Space between icon and text */
  transition: transform 0.3s ease-in-out;
}

.contact-icon:hover {
  transform: scale(1.2); /* Slightly enlarge the icon on hover */
}

.contact a {
  color: #ffffff; /* Email link color */
  text-decoration: none; /* Remove underline from link */
  font-weight: bold; /* Make the link text bold */
  transition: color 0.3s ease-in-out; /* Smooth color transition */
}

.contact a:hover {
  text-decoration: underline; /* Underline on hover */
  color: #ffeb3b; /* Change color on hover */
}

.countdown {
  font-size: 2em; /* Size of the countdown text */
  margin-top: 10px; /* Space above the countdown */
}

.contact-text {
  margin: 10px 0; /* Space above and below the additional text */
  font-size: 1.2em;
}

.tagline {
  font-size: 1em; /* Adjust the font size as needed */
  margin-top: 10px; /* Space above the tagline */
  color: #ffeb3b; /* Yellow color to make it stand out */
  font-weight: bold; /* Make the tagline bold */
  text-transform: uppercase; /* Transform text to uppercase */
  letter-spacing: 0.05em; /* Add some spacing between the letters */
  animation: fadeIn 1.5s ease-in-out; /* Apply a fade-in animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}